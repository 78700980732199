import { Article } from "@/globalTypes";

export const getResponsiveImageUrl = (article: Article) => {
    if (!article.banner) return '';

    // Get window width to determine the device type
    const width = window.innerWidth;

    // Mobile: width <= 768px
    if (width <= 768) {
        return article.banner.small?.url || article.banner.thumbnail?.url || '';
    }

    // Tablet: width > 768px and <= 1024px
    if (width > 768 && width <= 1024) {
        return (
            article.banner.medium?.url ||
            article.banner.small?.url ||
            article.banner.thumbnail?.url ||
            ''
        );
    }

    // Desktop: width > 1024px
    return (
        article.mainImage?.url || // New main image for desktop
        article.banner.large?.url || 
        article.banner.medium?.url || 
        article.banner.small?.url || 
        article.banner.thumbnail?.url || 
        ''
    );
};
