import { Inter, Noto_Sans, Noto_Serif, Ubuntu, Roboto, Great_Vibes } from 'next/font/google'; 
import { FeatureDescriptions, Months, Rashi, Testimonial } from './globalTypes';
export const revalidate = { revalidate: 3600 };
export const inter = Inter({ subsets: ['latin'], variable:'--font-inter', });
export const noto_sans = Noto_Sans({
    display: 'swap',
    style: "normal",
    variable:'--font-sans',
    subsets: ['latin']
});
export const noto_serif = Noto_Serif({
    display: 'swap',
    style: "normal",
    variable:'--font-serif',
    subsets: ['latin']
});
export const ubuntu = Ubuntu({
    display: 'swap',
    style: 'normal',
    variable:'--font-ubuntu',
    subsets: ['latin'],
    weight: ['400','500','700'],

});

export const roboto = Roboto({
    display: 'swap',
    style: 'normal',
    variable:'--font-robot',
    subsets: ['latin'],
    weight: '400',

});
export const great_vibes = Great_Vibes({
    display: 'swap',
    style: 'normal',
    subsets: ['latin'],
    variable:'--font-good-vibes',
    weight: '400',

});
export const DEMO_DESCRIPTIONS: FeatureDescriptions[] = [
    {
        icon: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/CalendarCheck.svg`,
        heading: "Daily Calendar",
        descriptions: [
            "Stay informed about important dates with our easy-to-use Hindu calendar",
            "Find auspicious & inauspicious times during the day",
            "Set reminders for good and bad muhurta",
        ],
        alt: "Calendar Icon"
    },
    {
        icon: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/SunHorizon.svg`,
        heading: "Panchanga",
        descriptions: [
            "Find Tithi, Vaara, Nakshatra, Yoga, and Karana",
            "View Panchanga for past and future dates",
        ],
        alt: "Sun Set Icon"
    },
    {
        icon: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/HourglassMedium.svg`,
        heading: "Shubh Muhurta",
        descriptions: [
            "Align yours actions with favourable celestial moments",
            "Find auspicious & inauspicious times during the day",
            "Find auspicious days for starting new project, ceremonies, and special occasions",

        ],
        alt: "Hour glass icon"
    },
    {
        icon: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/lamp.svg`,
        heading: "Festivals and Observances",
        descriptions: [
            "Find dates for festivals, and observances like Amavasya, Ekadasi and more",
            "Get timely reminder to prepare for speical days",
            "Learn about the significance of each special day",
        ],
        alt: "Lamp icon"
    },
    {
        icon: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/book.svg`,
        heading: "Learn More",
        descriptions: [

            "Broaden your understanding of the hindu calendar system",
            "Learn about Panchanga and its elements, and insights on speical days",
        ],
        alt: "Book icon"
    },
    {
        icon: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/PrarthnaIcon.svg`,
        heading: "Prarthna",
        descriptions: [
            'Find prayers for your ista-devata - along with its meaning.',
            'Daily 5-minute prayer is now very easy with a customised daily prayer list.'
        ],
        alt: "Prarthna icon"
    },
];
export const TESTIMONIALS: Testimonial[] = [
    {
        author: "Vivek79 Yadav",
        review: "I discovered this app through Reddit, and wow, this app is amazing. I don't have any problems with Dharmayana yet, so that's good. I also love the aesthetics of the app.",
        stars: "5",
        date: "January 15, 2024",
    },
    {
        author: "Ramkumar Durgam",
        review: "Excellent app with a very clean UX and accurate information. Must have for anyone who follows the Hindu calendar.",
        stars: "5",
        date: "January 4, 2024",
    },
    {
        author: "Niranjan Joshi",
        review: "Very nice and accurate tool. Awaiting Festival module to be launched to provide the details of what the festivals r about",
        stars: "5",
        date: "January 27, 2024"
    },
    {
        author: "Anupama rao",
        review: `A great one stop app for instant info about auspicious days and dates.`,
        stars: "5",
        date: "January 2, 2024"
    },
    {
        author: "Varma Alluri",
        review: `Very useful app with panchanga information in your fingertips.`,
        stars: "5",
        date: "January 4, 2024"
    },


];
export const APP_SCREEN_SHOTS: string[] = [
    "App's Home page demo screenshot",
    "App's  Panchanga page demo screenshot",
    "App's 'good day for' search feature screenshot",
    "App's Festival page demo screenshot",
    "App's home demo screenshot",

];
export const daysOfWeek: {[day:string]:string}  = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
    Sun: "Sunday",
};
export const MONTHS:Months = {
    'JANUARY': [],
    'FEBRUARY': [],
    'MARCH': [],
    'APRIL': [],
    'MAY': [],
    'JUNE': [],
    'JULY': [],
    'AUGUST': [],
    'SEPTEMBER': [],
    'OCTOBER': [],
    'NOVEMBER': [],
    'DECEMBER': [],
};

export const DEFAULT_LOCATION = {
    lat: 23.41667,
    long: 75.5,
    city: 'Ujjain',
};
export const RASHIES: Rashi[] = [
    {
      vedic_name: 'Mesha',
      english_name: 'Aries',
      image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/aries-mesha.svg`
    },
    {
      vedic_name: 'Vrishabha',
      english_name: 'Taurus',
      image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/taurus-vrishabha.svg`
    },
    {
      vedic_name: 'Mithuna',
      english_name: 'Gemini',
      image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/gemini-mithuna.svg`
    },
    {
      vedic_name: 'Karka',
      english_name: 'Cancer',
      image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/cancer-karka.svg`
    },
    {
      vedic_name: 'Simha',
      english_name: 'Leo',
      image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/leo-simha.svg`
    },
    {
      vedic_name: 'Kanya',
      english_name: 'Virgo',
      image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/virgo-kanya.svg`
    },
    {
      vedic_name: 'Tula',
      english_name: 'Libra',
      image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/libra-tula.svg`
    },
    {
      vedic_name: 'Vrischika',
      english_name: 'Scorpio',
      image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/scorpio-vrischika.svg`
    },
    {
      vedic_name: 'Dhanu',
      english_name: 'Sagittarius',
      image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/sagittarius-dhanu.svg`
    },
    {
      vedic_name: 'Makara',
      english_name: 'Capricorn',
      image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/capricorn-makara.svg`
    },
    {
      vedic_name: 'Kumbha',
      english_name: 'Aquarius',
      image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/aquarius-kumbha.svg`
    },
    {
      vedic_name: 'Meena',
      english_name: 'Pisces',
      image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/pisces-meena.svg`
    },
  ]