'use client'
import { noto_sans, ubuntu } from '@/globalConstants';
import { Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import Image from 'next/image';
import { usePathname, useRouter } from 'next/navigation';
import React from 'react';

interface BlogCardProps {
    thumbnailUrl: string;
    tags: string[];
    heading: string;
    summary: string;
    publishDate: string; // can be string or Date format
    readTime: number;
    orientation: string;
    slug:string;
}

const BlogCard: React.FC<BlogCardProps> = ({
    thumbnailUrl,
    tags,
    heading,
    summary,
    publishDate,
    readTime,
    orientation,
    slug
}) => {
    const pathname = usePathname();
    const router = useRouter();

    return (
        <div className={`  ${orientation === 'flex' ? ' md:flex ' : ''} ${orientation === 'grid' ?'sm:w-[340px] ':''}  grid  gap-4  `}
            onMouseOver={() => {
                const blogCardImage = document.getElementById(`${slug}`);
                if (blogCardImage) {
                    blogCardImage.classList.toggle('scale-[105%]');
                }

            }}
            onMouseOut={() => {
                const blogCardImage = document.getElementById(`${slug}`);
                if (blogCardImage) {
                    blogCardImage.classList.toggle('scale-[105%]');
                }

            }}
        >
            {thumbnailUrl === '' ? <Skeleton variant='rounded' animation="wave" className=" w-[340px] h-[250px]  "></Skeleton> :

                <div className={`blog-card-img relative h-[280px] ${orientation === 'flex' ? 'md:min-w-[340px] md:max-w-[340px]' : ''} rounded-lg overflow-hidden  transition ease-in-out delay-150  duration-300 `} id={slug}>
                    <div className="absolute inset-0 bg-center bg-no-repeat bg-cover filter blur-lg " style={{ backgroundImage: `url(${thumbnailUrl})` }} />

                    <Image
                        src={thumbnailUrl}
                        alt={heading}
                        className={`relative z-10 object-contain rounded-[8px] w-full h-full `}
                        width={1}
                        height={1}
                        priority
                        loading='eager'
                    />
                </div>}
            <div className={` flex flex-col gap-[12px] w-full `}>
                {tags ? <div className={` flex-wrap gap-2 ${tags.length ? 'flex' : 'hidden'} item-center`}>
                    {tags.map((tag: { name: string } | string, index) => (

                        <span
                            key={index}

                            className={`${noto_sans.className} bg-white text-[#EE9322] text-[14px] font-[600] rounded p-[0.3rem] transition ease-in-out delay-150  hover:text-white hover:bg-[#EE9322]  duration-300`}
                            onClick={(event) => {
                                    event.preventDefault();
                                router.push(`/articles/tags/${(typeof tag === 'string' ? tag : tag.name).replaceAll(" ", "-")}`)
                            }}
                        >
                            {(typeof tag === 'string' ? tag : tag.name)}
                        </span>
                    ))}
                </div> : ''}
                <h2 className={`${ubuntu.className} font-[500] text-[#1D4749]  text-[12px] sm:text-[18px] font-[600] h-[] mt-2`}>
                    {heading.length ? heading : <Skeleton variant='text' animation='wave'></Skeleton>}
                </h2>
                <p className={`text-[#565151] text-[10px] sm:text-[12px] font-medium mt-1 ${noto_sans.className}`}>
                    {summary.length ? summary : <Skeleton variant='text' animation='wave'></Skeleton>}
                </p>
                <div className={`text-[#8F8B8B] text-[12px] mt-2 ${ubuntu.className}`}>
                    <span>{publishDate?.length ? dayjs(publishDate).format("DD-MMM-YYYY").replaceAll('-', " ") : ''}</span>  •  <span>{readTime ? `${readTime} min read` : <Skeleton variant='text' animation='wave' ></Skeleton>} </span>
                </div>
            </div>
        </div >
    );
};

export default BlogCard;
