'use client'
import React, { useEffect, useState } from 'react'
import BlogCard from './BlogCard'
import Link from 'next/link'
import Image from 'next/image';
import { noto_serif, ubuntu } from '@/globalConstants';
import { getResponsiveImageUrl } from '@/lib/globalUtils';


const LatestListing = ({ baseClass }: { baseClass: string }) => {
    const [latestListing, setLatestListing] = useState([])
    useEffect(() => {
        (async () => {

            let latestArticles = (await (await fetch(`${process.env.NEXT_PUBLIC_STRAPI_BASE_URL}blogs`)).json());
            setLatestListing(latestArticles.splice(0, 3));
        })()
    }, [])

    return (
        <>
            <div className={`${baseClass} flex flex-col gap-[30px] items-center `}>
                <div className={`${baseClass}__apps__heading flex gap-[8px] justify-center`}>
                    <Image src="/union.svg" alt="union icon" width="30" height="6" />
                    <h1 className={`${noto_serif.className} font-[600] text-[1.25rem]  md:text-[2rem] text-[#1D4749] text-center`}>Read Latest Articles</h1>
                    <Image src="/union.svg" alt="union icon" width="30" height="6" className='rotate-180  ' />
                </div>
                <div className={`${baseClass}__apps__panchanga&muhurata flex flex-wrap gap-[1.5rem] md:gap-[1.9rem] xl:gap-[4rem] px-[1.5rem]  justify-center `}>
                    {
                        latestListing.map((article: any) => {
                            return (
                                <Link key={Math.random()} href={`/articles/${article.slug}`} className='block  h-auto'>
                                    <BlogCard thumbnailUrl={getResponsiveImageUrl(article)} tags={article.tags} heading={article.heading} summary={article.tagline} publishDate={article.publishDate} readTime={article.readTime} orientation={'grid'} slug={article.slug} />
                                </Link>
                            )
                        })
                    }
                </div>
                <div className={`${baseClass}__apps__footer flex gap-[8px] justify-center`}>
                    <Link key={Math.random()} href={`/articles`} className='transition ease-in-out delay-150  hover:scale-110  duration-300 '>
                        <button className={` w-[196px] h-[48px] py-[10px] px-[20px] rounded-[16px] border-1 border-[#E28C20] bg-[#EE9322] text-white ${ubuntu.className} font-[500] text-[16px]`}>  VIEW ALL ARTICLES</button>
                    </Link>

                </div>
            </div>





        </>
    )
}

export default LatestListing